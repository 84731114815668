@import '../../partials/colors';
@import '../../partials/mixins';

.hero {
	width: 100%;
	height: 100vh;
	background-size: cover;
	background-image: url('../../assets/images/hero_home.jpeg');
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	padding: 0 90px;
	@include tablet {
		padding: 0 4rem;
	}
	@include mobile {
		padding: 0 2rem;
	}
	&__titlem {
		color: #fff;
		font-size: 2.3rem;
		font-weight: 500;
		line-height: 2rem;
		@include tablet {
			font-size: 1.7rem;
		}
		@include mobile {
			font-size: 1.3rem;
		}
	}
	&__titlel {
		color: #fff;
		font-size: 3.8rem;
		font-weight: 500;
		@include tablet {
			font-size: 2.7rem;
		}
		@include tablet {
			font-size: 1.45rem;
			margin: 0.2rem 0;
		}
	}
	&__titles {
		color: #fff;
		font-size: 1rem;
		font-weight: 500;
	}
	&__button {
		color: #fff;
		background-color: #458032;
		margin-top: 1rem;
		width: 130px;
		height: 40px;
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 1rem;
		font-weight: 500;
		@include tablet {
			width: 110px;
			height: 35px;
			font-size: 0.8rem;
		}
	}
}