@import '../../partials/colors';
@import '../../partials/mixins';

.education {
	background-color: #111;
	padding: 4rem 3rem 5rem;
	display: flex;
	justify-content: center;
	@include mobile {
		padding: 2rem 1rem;
	}
	&__box {
		width: 100%;
		max-width: 1000px;
		display: flex;
		flex-direction: column;
		align-items: center;
		@include tablet {
			max-width: 750px;
		}
	}
	&__title {
		color: #fff;
		font-size: 1.875rem;
		margin-bottom: 2rem;
		@include mobile {
			font-size: 1.5rem;
		}
	}
	&__list {
		display: flex;
		justify-content: space-between;
		width: 100%;
		@include mobile {
			flex-direction: column;
			justify-content: center;
			align-items: center;
		}
	}
}

.educard {
	width: 290px;
	padding: 40px 20px;
	background: #222;
	color: #fff;
	// max-width: 340px;
	// margin: 20px;
	text-align: center;
	transition: 0.5s;
	display: flex;
	flex-direction: column;
	align-items: center;
	&:hover {
		background: #458032;
	}
	@include tablet {
		width: 210px;
		padding: 30px 5px;
	}
	@include mobile {
		width: 100%;
		max-width: 350px;
		padding: 40px 10px 50px 10px;
		margin-bottom: 1rem;
	}
	&__img {
		filter: invert(1);
		width: 150px;
		height: 150px;
		object-fit: cover;
		@include tablet {
			width: 100px;
			height: 100px;
		}
		@include mobile {
			width: 150px;
			height: 150px;
		}
	}
	&__title {
		font-size: 0.95rem;
		margin: 0.5rem 0 0.1rem;
		@include tablet {
			font-size: 0.7rem;
		}
		@include mobile {
			font-size: 1rem;
			margin: 1rem 0 0.3rem;
		}
	}
	&__subtitle {
		font-size: 0.9rem;
		font-weight: 500;
		@include tablet {
			font-size: 0.65rem;
		}
		@include mobile {
			font-size: 0.9rem;
		}
	}
	&__text {
		font-size: 0.8rem;
		margin-top: 0.3rem;
		@include tablet {
			font-size: 0.63rem;
			margin-top: 0.1rem;
		}
		@include mobile {
			font-size: 0.8rem;
		}
	}
}