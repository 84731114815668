@import '../../partials/colors';
@import '../../partials/mixins';

.about {
	padding: 4rem 5rem 0;
	display: flex;
	justify-content: center;
	align-items: center;
	@include mobile {
		padding: 2rem 1rem;
	}
	&__box {
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 100%;
		max-width: 1200px;
	}
	&__title {
		font-size: 1.875rem;
		margin-bottom: 2rem;
		@include mobile {
			font-size: 1.5rem;
		}
	}
	&__contentbox {
		display: flex;
		@include mobile {
			flex-direction: column-reverse;
		}
	}
	&__textbox {
		padding-right: 2.5rem;
		@include mobile {
			padding-right: 0;
		}
	}
	&__subtitle {
		margin-bottom: 0.625rem;
		font-size: 1.5rem;
		@include tablet {
			font-size: 1.2rem;
		}
		@include mobile {
			text-align: center;
			margin-top: 1rem;
		}
		@include mobile {
			font-size: 1.2rem;
		}
	}
	&__text {
		font-size: 1rem;
		@include tablet {
			font-size: 0.8rem;
		}
		@include mobile {
			text-align: center;
			font-size: 0.7rem;
		}
	}
	&__image {
		object-fit: cover;
		width: 100%;
		height: 100%;
	}
}