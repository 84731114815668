@import './fonts';

* {
	padding: 0;
	margin: 0;
	font-family: 'Poppins', sans-serif;
	scroll-behavior: smooth;
}
html {
	box-sizing: border-box;
}
*, *:before, *:after {
	box-sizing: inherit;
}
img {
	display: block; //fix minor vertical positioning issue
}
a {
	text-decoration: none;
}
ul, li {
	list-style-type: none;
}
textarea {
	resize: none;
}
button {
	outline: none;
	border: none;
	cursor: pointer;
}
html, body {
	width: 100%;
	overflow-x: hidden;
}