@import '../../partials/colors';
@import '../../partials/mixins';

.contact {
	background-color: #111;
	padding: 3rem 4rem;
	display: flex;
	flex-direction: column;
	align-items: center;
	@include mobile {
		padding: 2rem 1rem;
	}
	&__title {
		color: #fff;
		text-align: center;
		margin-bottom: 2rem;
		@include mobile {
			font-size: 1.5rem;
		}
	}
	&__contentbox {
		width: 100%;
		max-width: 1000px;
		display: flex;
		justify-content: center;
		@include mobile {
			flex-direction: column;
		}
	}
	&__infobox {
		width: 650px;
	}
	&__subtitle {
		color: #fff;
		margin-bottom: 1rem;
	}
	&__info {
		display: flex;
		margin-bottom: 1.5rem;
	}
	&__icn {

	}
	&__details {
		padding-left: 1rem;
	}
	&__infotitle {
		color: #458032;
		font-size: 1rem;
	}
	&__address {
		color: #fff;
		font-size: 0.8rem;
	}
	&__form {
		display: flex;
		flex-direction: column;
		width: 100%;
		align-items: flex-start;
	}
	&__input {
		background: #222;
		color: #fff;
		border: none;
		outline: none;
		width: 100%;
		font-size: 0.9rem;
		padding: 0.6rem 0.8rem;
		margin-bottom: 1rem;
	}
	&__textarea {
		background: #222;
		color: #fff;
		border: none;
		outline: none;
		width: 100%;
		height: 150px;
		font-size: 0.9rem;
		padding: 0.4rem 0.8rem;
	}
	&__button {
		background-color: #458032;
		color: #fff;
		width: 90px;
		height: 45px;
		font-weight: 600;
		margin-top: 1rem;
	}
}

.icon {
	color: #fff;
	width: 40px;
	padding-top: 0.4rem;
	display: flex;
	justify-content: center;
	align-items: flex-start;
}
.fa {
	font-size: 20px;
}