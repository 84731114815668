@import '../../partials/colors';
@import '../../partials/mixins';

.skills {
	padding: 4rem 4rem 5rem;
	display: flex;
	flex-direction: column;
	align-items: center;
	@include mobile {
		padding: 2rem 2rem 3rem;
	}
	&__title {
		margin-bottom: 2rem;
	}
	&__list {
		width: 100%;
		max-width: 900px;
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
	}
}

.skillbox {
	width: 290px;
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	margin-bottom: 0.3rem;
	margin: 0 5px 0.3rem 5px;
	@include mobile {
		width: 100%;
	}
	&__skill {
		font-size: 0.8rem;
		color: rgba(0,0,0,0.6);
	}
	&__outer {
		width: 100%;
		height: 10px;
		border: 1px solid rgba(0,0,0,0.3);
		border-radius: 6px;
		display: flex;
		align-items: center;
		padding: 0 2px;
	}
	&__inner {
		height: 6px;
		background-color: #458032;
		border-radius: 6px;
		&--5 {
			width: 100%;
		}
		&--4 {
			width: 80%;
		}
		&--3 {
			width: 60%;
		}
	}
}