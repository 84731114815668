@import './fonts';

@mixin mobile {
	@media (max-width: 767px) {
		@content;
	}
}
@mixin tablet {
	@media (max-width: 1023px) {
		@content;
	}
}
@mixin desktop {
	@media (min-width: 1024px) {
		@content;
	}
}