@import url(https://fonts.googleapis.com/css?family=Poppins:200,300,400,500,600,700,800,900&display=swap);
@import url(https://fonts.googleapis.com/css?family=Poppins:200,300,400,500,600,700,800,900&display=swap);
@import url(https://fonts.googleapis.com/css?family=Poppins:200,300,400,500,600,700,800,900&display=swap);
@import url(https://fonts.googleapis.com/css?family=Poppins:200,300,400,500,600,700,800,900&display=swap);
@import url(https://fonts.googleapis.com/css?family=Poppins:200,300,400,500,600,700,800,900&display=swap);
@import url(https://fonts.googleapis.com/css?family=Poppins:200,300,400,500,600,700,800,900&display=swap);
@import url(https://fonts.googleapis.com/css?family=Poppins:200,300,400,500,600,700,800,900&display=swap);
@import url(https://fonts.googleapis.com/css?family=Poppins:200,300,400,500,600,700,800,900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.header{position:fixed;backgorund-color:rgba(0,0,0,0);display:flex;justify-content:space-between;width:100%;padding:1.8rem 5rem;transition:0.5s;z-index:1000}@media (max-width: 1023px){.header{padding:1.5rem 3rem}}@media (max-width: 767px){.header{padding:1rem 1.5rem}}.header__mainlink{color:#fff;font-size:1.45rem;font-weight:700}@media (max-width: 1023px){.header__mainlink{font-size:1.2rem}}.header__nav{display:flex;align-items:center}@media (max-width: 767px){.header__nav{display:none}}.header__link{color:#fff;font-size:0.9rem;margin:0 0.5rem;font-weight:500}@media (max-width: 1023px){.header__link{font-size:0.75rem}}.sticky{background:#458032;padding:0.5rem 5rem;box-shadow:0 5px 20px rgba(0,0,0,0.1)}@media (max-width: 1023px){.sticky{padding:0.5rem 3rem}}@media (max-width: 767px){.sticky{padding:1rem 1.5rem}}.bm-burger-button{display:none}@media (max-width: 767px){.bm-burger-button{display:block;position:fixed;width:33px;height:23px;right:24px;top:18px}}.bm-burger-bars{background:white;border-radius:10px}.bm-burger-bars-hover{background:#458032}.bm-cross-button{height:24px;width:24px}.bm-cross{background:#bdc3c7}.bm-menu-wrap{position:fixed;height:100%;top:0;left:0}.bm-menu{background:#373a47;padding:2.5em 1.5em 0;font-size:1.15em}.bm-morph-shape{fill:#373a47}.bm-item-list{color:#b8b7ad;padding:0.8em}.bm-item{display:inline-block}.bm-overlay{background:rgba(0,0,0,0.3);top:0;left:0}.burgerLink{color:white;margin-bottom:1rem}.burgerLink--button{margin-top:4rem}

.hero{width:100%;height:100vh;background-size:cover;background-image:url(/static/media/hero_home.2a9355bc.jpeg);display:flex;flex-direction:column;justify-content:center;align-items:flex-start;padding:0 90px}@media (max-width: 1023px){.hero{padding:0 4rem}}@media (max-width: 767px){.hero{padding:0 2rem}}.hero__titlem{color:#fff;font-size:2.3rem;font-weight:500;line-height:2rem}@media (max-width: 1023px){.hero__titlem{font-size:1.7rem}}@media (max-width: 767px){.hero__titlem{font-size:1.3rem}}.hero__titlel{color:#fff;font-size:3.8rem;font-weight:500}@media (max-width: 1023px){.hero__titlel{font-size:2.7rem}}@media (max-width: 1023px){.hero__titlel{font-size:1.45rem;margin:0.2rem 0}}.hero__titles{color:#fff;font-size:1rem;font-weight:500}.hero__button{color:#fff;background-color:#458032;margin-top:1rem;width:130px;height:40px;display:flex;justify-content:center;align-items:center;font-size:1rem;font-weight:500}@media (max-width: 1023px){.hero__button{width:110px;height:35px;font-size:0.8rem}}

.about{padding:4rem 5rem 0;display:flex;justify-content:center;align-items:center}@media (max-width: 767px){.about{padding:2rem 1rem}}.about__box{display:flex;flex-direction:column;align-items:center;width:100%;max-width:1200px}.about__title{font-size:1.875rem;margin-bottom:2rem}@media (max-width: 767px){.about__title{font-size:1.5rem}}.about__contentbox{display:flex}@media (max-width: 767px){.about__contentbox{flex-direction:column-reverse}}.about__textbox{padding-right:2.5rem}@media (max-width: 767px){.about__textbox{padding-right:0}}.about__subtitle{margin-bottom:0.625rem;font-size:1.5rem}@media (max-width: 1023px){.about__subtitle{font-size:1.2rem}}@media (max-width: 767px){.about__subtitle{text-align:center;margin-top:1rem}}@media (max-width: 767px){.about__subtitle{font-size:1.2rem}}.about__text{font-size:1rem}@media (max-width: 1023px){.about__text{font-size:0.8rem}}@media (max-width: 767px){.about__text{text-align:center;font-size:0.7rem}}.about__image{object-fit:cover;width:100%;height:100%}

.education{background-color:#111;padding:4rem 3rem 5rem;display:flex;justify-content:center}@media (max-width: 767px){.education{padding:2rem 1rem}}.education__box{width:100%;max-width:1000px;display:flex;flex-direction:column;align-items:center}@media (max-width: 1023px){.education__box{max-width:750px}}.education__title{color:#fff;font-size:1.875rem;margin-bottom:2rem}@media (max-width: 767px){.education__title{font-size:1.5rem}}.education__list{display:flex;justify-content:space-between;width:100%}@media (max-width: 767px){.education__list{flex-direction:column;justify-content:center;align-items:center}}.educard{width:290px;padding:40px 20px;background:#222;color:#fff;text-align:center;transition:0.5s;display:flex;flex-direction:column;align-items:center}.educard:hover{background:#458032}@media (max-width: 1023px){.educard{width:210px;padding:30px 5px}}@media (max-width: 767px){.educard{width:100%;max-width:350px;padding:40px 10px 50px 10px;margin-bottom:1rem}}.educard__img{-webkit-filter:invert(1);filter:invert(1);width:150px;height:150px;object-fit:cover}@media (max-width: 1023px){.educard__img{width:100px;height:100px}}@media (max-width: 767px){.educard__img{width:150px;height:150px}}.educard__title{font-size:0.95rem;margin:0.5rem 0 0.1rem}@media (max-width: 1023px){.educard__title{font-size:0.7rem}}@media (max-width: 767px){.educard__title{font-size:1rem;margin:1rem 0 0.3rem}}.educard__subtitle{font-size:0.9rem;font-weight:500}@media (max-width: 1023px){.educard__subtitle{font-size:0.65rem}}@media (max-width: 767px){.educard__subtitle{font-size:0.9rem}}.educard__text{font-size:0.8rem;margin-top:0.3rem}@media (max-width: 1023px){.educard__text{font-size:0.63rem;margin-top:0.1rem}}@media (max-width: 767px){.educard__text{font-size:0.8rem}}

.project{display:flex;flex-direction:column;align-items:center;padding:3rem 4rem}@media (max-width: 767px){.project{padding:2rem 1rem}}.project__title{font-size:1.875rem}@media (max-width: 767px){.project__title{font-size:1.5rem}}.project__subtitle{font-weight:500;margin-bottom:2rem}@media (max-width: 767px){.project__subtitle{font-size:1rem}}.project__list{width:100%;max-width:1200px}.projectcard{display:flex;justify-content:center;border:1px solid black;border-radius:3px;overflow:hidden;margin-bottom:1.5rem}@media (max-width: 767px){.projectcard{flex-direction:column}}.projectcard__img{object-fit:cover;width:220px}@media (max-width: 767px){.projectcard__img{width:100%}}.projectcard__textbox{padding:1rem;display:flex;flex-direction:column}.projectcard__title{font-size:1.2rem}.projectcard__description{font-size:0.8rem;margin:0.5rem 0}.projectcard__link{background-color:#458032;border-radius:5px;color:#fff;font-size:0.7rem;margin-right:0.4rem;padding:0.1rem 0.3rem}

.skills{padding:4rem 4rem 5rem;display:flex;flex-direction:column;align-items:center}@media (max-width: 767px){.skills{padding:2rem 2rem 3rem}}.skills__title{margin-bottom:2rem}.skills__list{width:100%;max-width:900px;display:flex;flex-wrap:wrap;justify-content:center}.skillbox{width:290px;display:flex;flex-direction:column;align-items:flex-end;margin-bottom:0.3rem;margin:0 5px 0.3rem 5px}@media (max-width: 767px){.skillbox{width:100%}}.skillbox__skill{font-size:0.8rem;color:rgba(0,0,0,0.6)}.skillbox__outer{width:100%;height:10px;border:1px solid rgba(0,0,0,0.3);border-radius:6px;display:flex;align-items:center;padding:0 2px}.skillbox__inner{height:6px;background-color:#458032;border-radius:6px}.skillbox__inner--5{width:100%}.skillbox__inner--4{width:80%}.skillbox__inner--3{width:60%}

.contact{background-color:#111;padding:3rem 4rem;display:flex;flex-direction:column;align-items:center}@media (max-width: 767px){.contact{padding:2rem 1rem}}.contact__title{color:#fff;text-align:center;margin-bottom:2rem}@media (max-width: 767px){.contact__title{font-size:1.5rem}}.contact__contentbox{width:100%;max-width:1000px;display:flex;justify-content:center}@media (max-width: 767px){.contact__contentbox{flex-direction:column}}.contact__infobox{width:650px}.contact__subtitle{color:#fff;margin-bottom:1rem}.contact__info{display:flex;margin-bottom:1.5rem}.contact__details{padding-left:1rem}.contact__infotitle{color:#458032;font-size:1rem}.contact__address{color:#fff;font-size:0.8rem}.contact__form{display:flex;flex-direction:column;width:100%;align-items:flex-start}.contact__input{background:#222;color:#fff;border:none;outline:none;width:100%;font-size:0.9rem;padding:0.6rem 0.8rem;margin-bottom:1rem}.contact__textarea{background:#222;color:#fff;border:none;outline:none;width:100%;height:150px;font-size:0.9rem;padding:0.4rem 0.8rem}.contact__button{background-color:#458032;color:#fff;width:90px;height:45px;font-weight:600;margin-top:1rem}.icon{color:#fff;width:40px;padding-top:0.4rem;display:flex;justify-content:center;align-items:flex-start}.fa{font-size:20px}


*{padding:0;margin:0;font-family:'Poppins', sans-serif;scroll-behavior:smooth}html{box-sizing:border-box}*,*:before,*:after{box-sizing:inherit}img{display:block}a{text-decoration:none}ul,li{list-style-type:none}textarea{resize:none}button{outline:none;border:none;cursor:pointer}html,body{width:100%;overflow-x:hidden}

