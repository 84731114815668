@import '../../partials/colors';
@import '../../partials/mixins';

.header {
	position: fixed;
	backgorund-color: rgba(0,0,0,0);
	display: flex;
	justify-content: space-between;
	width: 100%;
	padding: 1.8rem 5rem;
	transition: 0.5s;
	z-index: 1000;
	@include tablet {
		padding: 1.5rem 3rem;
	}
	@include mobile {
		padding: 1rem 1.5rem;
	}
	&__mainlink {
		color: #fff;
		font-size: 1.45rem;
		font-weight: 700;
		@include tablet {
			font-size: 1.2rem;
		}
	}
	&__nav {
		display: flex;
		align-items: center;
		@include mobile {
			display: none;
		}
	}
	&__link {
		color: #fff;
		font-size: 0.9rem;
		margin: 0 0.5rem;
		font-weight: 500;
		@include tablet {
			font-size: 0.75rem;
		}
	}
}

.sticky {
	background: #458032;
	padding: 0.5rem 5rem;
	box-shadow: 0 5px 20px rgba(0,0,0,0.1);
	@include tablet {
		padding: 0.5rem 3rem;
	}
	@include mobile {
		padding: 1rem 1.5rem;
	}
}

/* Position and sizing of burger button */
// .bm-burger-button {
//   position: absolute;
//   top: 30px;
//   right: 30px;
//   width: 25px;
//   height: 17px;
//   @include mobile {
//   	position: absolute;
// 	  top: 30px;
// 	  right: 30px;
// 	  width: 25px;
// 	  height: 17px;
//   }
//   @include tablet {
//   	display: none;
//   }
//   @include desktop {
//   	display: none;
//   }
// }
.bm-burger-button {
	display: none;
}

@media (max-width: 767px) {
	.bm-burger-button {
		display: block;
		position: fixed;
		width: 33px;
		height: 23px;
		right: 24px;
		top: 18px;
	}
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  // background: rgba(0,0,0,0.75);
  background: white;
  border-radius: 10px;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #458032;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
  top: 0;
  left: 0;
}

/* General sidebar styles */
.bm-menu {
  background: #373a47;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
}

/* Individual item */
.bm-item {
  display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
  top: 0;
  left: 0;
}

.burgerLink {
	color: white;
	margin-bottom: 1rem;
	&--button {
		margin-top: 4rem;
	}
}