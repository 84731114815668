@import '../../partials/colors';
@import '../../partials/mixins';

.project {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 3rem 4rem;
	@include mobile {
		padding: 2rem 1rem;
	}
	&__title {
		font-size: 1.875rem;
		@include mobile {
			font-size: 1.5rem;
		}
	}
	&__subtitle {
		font-weight: 500;
		margin-bottom: 2rem;
		@include mobile {
			font-size: 1rem;
		}
	}
	&__list {
		width: 100%;
		max-width: 1200px;
	}
}

.projectcard {
	display: flex;
	justify-content: center;
	border: 1px solid black;
	border-radius: 3px;
	overflow: hidden;
	margin-bottom: 1.5rem;
	@include mobile {
		flex-direction: column;
	}
	&__img {
		object-fit: cover;
		width: 220px;
		@include mobile {
			width: 100%;
		}
	}
	&__textbox {
		padding: 1rem;
		display: flex;
		flex-direction: column;
	}
	&__title {
		font-size: 1.2rem;
	}
	&__description {
		font-size: 0.8rem;
		margin: 0.5rem 0;
	}
	&__linkbox {

	}
	&__link {
		background-color: #458032;
		border-radius: 5px;
		color: #fff;
		font-size: 0.7rem;
		margin-right: 0.4rem;
		padding: 0.1rem 0.3rem;
	}
}